import React from "react";
import { useStaticQuery, graphql } from "gatsby";
import { css } from "@emotion/react";
import { Link } from "gatsby";

import Layout from "../layouts/default";
import SEO from "../components/seo";
import Hero from "../components/hero";
import Blog from "../components/blog";
import { PickupMemberList } from "../components/pickup-members-list";
import { NewMemberList } from "../components/new-members-list";
import { LatestAchievements } from "../components/latest-achievement";
import Activity from "../components/activity";
import { LatestEvents } from "../components/latest-events";
import { Label, LabelList } from "../components/label";
import { LocalNav } from "../components/local-nav";
import { ButtonGroup, MoreButtonStyle } from "../components/buttons";

import Typography from '../styles/typography';
import { SpritBox, SpritSection, Section, Container, SectionHeader } from "../styles/layouts";

const IndexPage = () => {

  const data = useStaticQuery(graphql`
    query ($official: [String] = ["master", "sub_master", "officer", "garden_mater", "craft_master", "gardener", "senior", "member"]) {
      allMember: allMembersJson(sort: {order: ASC, fields: index}) {
        edges {
          node {
            slug
          }
        }
      }
      officialMember: allMembersJson(filter: {role: {in: $official}}, sort: {order: ASC, fields: index}) {
        edges {
          node {
            slug
          }
        }
      }
      traialMember: allMembersJson(filter: {role: {in: "trial"}}, sort: {order: ASC, fields: index}) {
        edges {
          node {
            slug
          }
        }
      }
    }
  `);

  return (
    <Layout>
      <SEO title="ホーム" />
      <Hero />
      <LocalNav />
      <SpritBox>
        <SpritSection css={css` background: #F0F3F4; `}>
          <SectionHeader>
            <Container>
              <h2 css={css`
                margin: 0;
                ${Typography.h2}
              `}>Members</h2>
              <LabelList>
                { !!data.traialMember.edges.length && <Label count={data.traialMember.edges.length}>Trial</Label> }
                <Label count={data.officialMember.edges.length}>Official</Label>
                <Label count={data.allMember.edges.length}>All</Label>
              </LabelList>
            </Container>
          </SectionHeader>
          <Container>
            <PickupMemberList />
            <NewMemberList />
          </Container>
        </SpritSection>
        <SpritSection css={css` background: #EAEDEE; `}>
          <SectionHeader>
            <Container>
              <h2 css={css`
                margin: 0;
                ${Typography.h2}
              `}>Latest Achievements</h2>
            </Container>
          </SectionHeader>
          <Container>
            <LatestAchievements />
          </Container>
        </SpritSection>
      </SpritBox>


      <Section css={css` background: #CEC1BC; `}>
        <SectionHeader>
          <Container>
            <h2 css={css`
              margin: 0;
              ${Typography.h2}
            `}>Recent Schedule</h2>
          </Container>
        </SectionHeader>
        <Container>
          <LatestEvents />
          <ButtonGroup>
            <Link to="/calendar" css={MoreButtonStyle}>More Schedule</Link>
          </ButtonGroup>
        </Container>
      </Section>

      <Section>
        <SectionHeader>
          <Container>
            <h2 css={css`
              margin: 0;
              ${Typography.h2}
            `}>Activities</h2>
          </Container>
        </SectionHeader>
        <Container>
          <Activity />
        </Container>
      </Section>

      <Section css={css` background: #EAEDEE; `}>
        <SectionHeader>
          <Container>
            <h2 css={css`
              margin: 0;
              ${Typography.h2}
            `}>Blog</h2>
          </Container>
        </SectionHeader>
        <Container>
          <Blog />
        </Container>
      </Section>
    </Layout>
  );
}

export default IndexPage;



