import React from "react";
import { useStaticQuery, graphql } from "gatsby";
import { Link } from "gatsby";

import { ButtonGroup, MoreButtonStyle } from "../components/buttons";
import { AchievementList, AchievementCard } from "./achievement-card";

export const LatestAchievements = (props) => {
  const data = useStaticQuery(graphql`
    query {
      allLatestAchievementsJson(limit: 5) {
        edges {
          node {
            date
            slug
            title
            url
            profile {
              slug
              profile {
                name {
                  full
                }
              }
              images {
                face
              }
            }
          }
        }
      }
    }
  `);

  return (
    <div {...props}>
      <AchievementList>
        {data.allLatestAchievementsJson.edges.map(({node}, index) => (
          <AchievementCard key={index} achievement={node} />
        ))}
      </AchievementList>
      <ButtonGroup>
        <Link to="/latest-achievements" css={MoreButtonStyle}>More Latest Achievements</Link>
      </ButtonGroup>
    </div>
  );
}

