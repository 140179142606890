import React, { useEffect, useState } from "react";
import { DateTime } from "luxon";
import { shuffle } from 'lodash-es';
import { useStaticQuery, graphql } from "gatsby";
import { css } from "@emotion/react";
import { Link } from "gatsby";
import { useInView } from "react-intersection-observer";
import { motion, useAnimation } from "framer-motion";

import CharaFaceImg from "./chara-face";
import { JobBadge } from "./job-icon";
import { breakpoints } from "../styles/layouts";
import { NewBadge } from "./badge";

const container = {
  hidden: { opacity: 1 },
  visible: {
    opacity: 1,
    transition: {
      when: "beforeChildren",
      staggerChildren: 0.1
    }
  }
}

const item = {
  hidden: { y: 20, opacity: 0 },
  visible: i => ({
    y: 0,
    opacity: 1,
    transition: {
      delay: i * 0.1,
    },
  }),
}

export const MemberFaceCard = ({ member }) => {
  const now = DateTime.local();
  const joinDate = DateTime.fromISO(member.join_date);
  const isNewest = (joinDate.plus({ days: 30 }) > now);

  return (
    <div css={MemberFaceCardStyle}>
      { member.current_job && <JobBadge
          className="member-current-job"
          data-job-type={member.current_job.type}
          slug={member.current_job.key}
          title={member.current_job.name}
          label={member.current_job.level} /> }
      <Link to={`/members/${member.slug}`}
        className="member-pointer"
        title={member.profile.name.full}>
        { isNewest && <NewBadge top={0} right={0} title="New Member" /> }
        <CharaFaceImg filename={member.images.face} className="member-image" width={72} height={72} />
        <span className="member-name">{member.profile.name.first}</span>
      </Link>
    </div>
  );
}

export const PickupMemberList = (props) => {
  const [shuffleMember, setShuffleMember] = useState(null);
  const data = useStaticQuery(graphql`
    query {
      allMembersJson {
        edges {
          node {
            slug
            profile {
              name {
                first
                full
              }
            }
            images {
              face
            }
            current_job {
              key
              name
              level
              type
            }
            join_date
          }
        }
      }
    }
  `);

  const controls = useAnimation();
  const [ref, inView] = useInView();

  useEffect(() => {
    if (inView) {
      controls.start("visible");
    }
  }, [controls, inView]);

  useEffect(() => {
    setShuffleMember(shuffle(data.allMembersJson.edges));
  }, [data.allMembersJson.edges]);

  const shuffleMemberList = () => {
    if (!shuffleMember) { return false; }

    return shuffleMember.slice(0,7).map((node, index) => {
      let member = node.node;

      return (
        <motion.li className="member-item" key={index} custom={index} variants={item}>
          <MemberFaceCard member={member} />
        </motion.li>
      );
    });
  }

  return (
    <motion.ul
      css={PickupMemberListStyle}
      ref={ref}
      animate={controls}
      variants={container}
      initial="hidden"
      {...props}>
      {shuffleMemberList()}
      <motion.li className="list-more" custom={shuffleMemberList().length} variants={item}>
        <Link to={`/members`} className="more-pointer">More</Link>
      </motion.li>
    </motion.ul>
  );
}

const MemberFaceCardStyle = css`
  position: relative;

  .member-pointer {
    display: block;

    color: #6D6F70;

    &:hover {
      text-decoration: none;
      color: #2B2D2E;
    }
  }

  .member-current-job {
    position: absolute;
    top: -.25rem;
    left: -.25rem;
  }

  .member-image {
    transition: transform .1s ease-in-out;
    margin-bottom: 1rem;

    &:hover {
      transform: scale(1.25);
    }

    &:active {
      transform: scale(1.125);
    }
  }

  .member-name {
    display: block;

    text-align: center;
    font-weight: bold;
  }
`;

const PickupMemberListStyle = css`
  display: flex;
  flex-wrap: wrap;
  margin: 0 -1rem;
  padding-inline-start: 0;

  justify-content: space-between;

  @media ${breakpoints.handheld} {
    justify-content: center;
  }

  list-style: none;

  .member-item {
    margin: 1rem;
  }



  .list-more {
    margin: 1rem;

    .more-pointer {
      display: block;
      widtH: 72px;
      height: 72px;
      border-radius: 50%;

      line-height: 72px;
      text-align: center;
      font-weight: bold;

      background: #EAEDEE;
      color: #828485;

      &:hover {
        text-decoration: none;

        background: #FAFDFE;
      }
    }
  }
`;
