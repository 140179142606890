import React from "react";
import { css } from "@emotion/react";
import { Link } from "gatsby";

import { ContainerStyle } from "../styles/layouts";
import { breakpoints } from "../styles/layouts";

import { library } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faQuestionCircle, faBookOpen, faDagger, faExternalLink} from '@fortawesome/pro-light-svg-icons';
import { faDiscord } from '@fortawesome/free-brands-svg-icons';

library.add(faQuestionCircle, faBookOpen, faDagger, faExternalLink, faDiscord);

export const LocalNav = () => {

  return (
    <div css={LocalNavSectionStyle}>
      <div className="inner">
        <div css={LocalNavStyle}>
          <ul className="nav-list">
            <li className="list-item">
              <Link to="/about" className="item-pointer">
                <span className="item-icon">
                  <FontAwesomeIcon className="icon-image" icon={["fal", "question-circle"]} />
                </span>

                <span className="item-title">About</span>
                <span className="item-summary">どんな FC？</span>
              </Link>
            </li>
            <li className="list-item">
              <a href="https://docs.asxiv.com/" className="item-pointer">
                <span className="item-outbound">
                  <FontAwesomeIcon className="outbound-image" icon={["fal", "external-link"]} />
                </span>
                <span className="item-icon">
                  <FontAwesomeIcon className="icon-image" icon={["fal", "book-open"]} />
                </span>

                <span className="item-title">Rulebook</span>
                <span className="item-summary">FCのお約束について</span>
              </a>
            </li>
            <li className="list-item">
              <a href="https://jp.finalfantasyxiv.com/lodestone/freecompany/9236319885783543512/" target="_blank" rel="noreferrer" className="item-pointer">
                <span className="item-outbound">
                  <FontAwesomeIcon className="outbound-image" icon={["fal", "external-link"]} />
                </span>
                <span className="item-icon">
                  <FontAwesomeIcon className="icon-image" icon={["fal", "dagger"]} />
                </span>

                <span className="item-title">The Lodestone</span>
                <span className="item-summary">公式のFC情報を確認</span>
              </a>
            </li>
            <li className="list-item">
              <a href="https://discord.com/channels/309271260645556227/" target="_blank" rel="noreferrer" className="item-pointer">
                <span className="item-outbound">
                  <FontAwesomeIcon className="outbound-image" icon={["fal", "external-link"]} />
                </span>
                <span className="item-icon">
                  <FontAwesomeIcon className="icon-image" icon={["fab", "discord"]} />
                </span>
                <span className="item-title">Discord</span>
                <span className="item-summary">FC 運営者用</span>
              </a>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
}

const LocalNavSectionStyle = css`
  border-top: 4px solid #EAEDEE;
  border-bottom: 4px solid #EAEDEE;

  background: #FAFDFE;

  @media ${breakpoints.xs} {
    padding: 2rem 0;
  }

  .inner {
    position: relative;
    ${ContainerStyle}
  }
`;

const LocalNavStyle = css`

  .nav-list {
    display: grid;
    grid-template-columns: repeat(4, 1fr);

    list-style: none;
    margin-block-start: 0;
    margin-block-end: 0;
    padding-inline-start: 0;
    padding-inline-end: 0;

    @media ${breakpoints.lg} {
      grid-template-columns: repeat(4, 1fr);
      /* column-gap: 5rem;
      row-gap: 5rem; */
    }

    @media ${breakpoints.md} {
      grid-template-columns: repeat(4, 1fr);
    }

    @media ${breakpoints.sm} {
      grid-template-columns: repeat(2, 1fr);
    }

    @media ${breakpoints.xs} {
      grid-template-columns: repeat(1, 1fr);
    }
  }

  .list-item {
    position: relative;

    &:after {
      position: absolute;
      display: block;
      content: "";
      top: 2rem;
      bottom: 2rem;
      right: 0;
      width: 1px;

      border-right: 1px solid #EAEDEE;

      @media ${breakpoints.xs} {
        top: auto;
        bottom: 0;
        right: 0;
        left: 0;
        width: auto;
        height: 1px;
        border-right: none;
        border-bottom: 1px solid #EAEDEE;
      }
    }

    &:last-child:after {
      display: none;
    }

    .item-outbound {
      opacity: 0;
      position: absolute;
      top: .5rem;
      right: .75rem;

      .outbound-image {
        font-size: .625rem;
        height: .625rem;
        color: #ACAEAF;
      }
    }
    .item-pointer:hover .item-outbound {
      opacity: 1;
    }

    .item-icon {
      display: block;

      @media ${breakpoints.xs} {
        float: left;
        margin-right: 1.5rem;
      }

      .icon-image {
        font-size: 1.5rem;
        height: 2.5rem;
        color: #ACAEAF;
      }
    }

    .item-pointer {
      position: relative;
      transition: background .1s ease-in-out;
      display: block;
      margin: .5rem;
      padding: 1.5rem;

      @media ${breakpoints.sm} {
        padding: 1rem 1rem;
      }

      @media ${breakpoints.xs} {
        padding: 1rem 1.5rem;
        text-align: left;
      }

      text-align: center;
      color: #4D4F50;

      border-radius: 6px;

      &:hover {
        text-decoration: none;
        color: #1D1E1F;

        background: #F0F3F4;
      }

      &:active {
        color: #1D1E1F;

        background: #EAEDEE;
      }
    }

    .item-title {
      display: block;

      font-weight: bold;
      letter-spacing: .05em;
      text-indent: .05em;
      font-size: 1.125rem;
    }

    .item-summary {
      display: block;
      color: #6D6F70;
      font-size: .75rem;
    }
  }
`;
