import React from "react";
import styled from "@emotion/styled";

export const Label = (props) => {
  return (
    <StyledLabel {...props}>
      {props.children}
      { !!props.count && <span className="count">{ props.count }</span> }
    </StyledLabel>
  );
}

export const LabelList = (props) => {
  return (
    <StyledLabelList {...props}>{props.children}</StyledLabelList>
  );
}

const StyledLabelList = styled.div`
  margin: 1rem 0;
`;

const StyledLabel = styled.span`
  display: inline-block;
  margin: 0 .25em;
  padding: 0 1em;

  &:first-child {
    margin-left: 0;
  }

  &:last-child {
    margin-right: 0;
  }

  line-height: inherit;
  text-align: center;
  letter-spacing: .05em;
  text-indent: .05em;
  font-size: .85em;

  background: rgba(0,0,0,.1);
  border-radius: 2em;

  .count {
    display: inline-block;
    margin-left: .75em;

    font-weight: bold;
  }
`;
