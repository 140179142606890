import React, { useState } from "react";
import { useStaticQuery, graphql } from "gatsby";
import Imgix, { Background } from "react-imgix";
import { css } from "@emotion/react";
import { DateTime } from "luxon";
import { motion } from "framer-motion";

import { breakpoints } from "../styles/layouts";

import LayerIndex from "../utils/layer-index";
import Typography from '../styles/typography';
import { NewBadge } from "../components/badge";

const container = {
  visible: {
    transition: {
      delay: 1,
      when: "beforeChildren",
      staggerChildren: 0.2
    }
  }
};

const item = {
  hidden: { y: 20, opacity: 0 },
  visible: {
    y: 0,
    opacity: 1
  }
}

const BackgroundSelectorItem = ({ screenshot, current, clickHandler }) => {
  const now = DateTime.local();
  let screenshotDate = DateTime.fromISO(screenshot.date);
  let isNewest = (screenshotDate.plus({ days: 3 }) > now);

  return (
    <li className="selector-item">
      { isNewest && <NewBadge right={0} top={0} /> }
      <button
        className="item-pointer"
        data-is-current={current}
        onClick={(e) => clickHandler(e, screenshot)}>
        <Imgix
          className="selector-image"
          src={`${process.env.GATSBY_WP_IMGIX_URL}/${screenshot.screenshot.mainscreenshot.mediaDetails.file}`}
          width={32}
          height={32}
          imgixParams={{ auto: 'compress,enhance,format', fit: 'crop', crop: 'entropy' }} />
      </button>
    </li>
  );
}

const Hero = (props) => {
  const data = useStaticQuery(graphql`
    query {
      allWpScreenshot(limit: 3, sort: {order: DESC, fields: date}) {
        edges {
          node {
            title
            date
            screenshot {
              mainscreenshot {
                mediaDetails {
                  file
                  height
                  width
                }
              }
            }
          }
        }
      }
    }
  `);

  const [currentBackground, setCurrentBackground] = useState(data.allWpScreenshot.edges[0].node);

  const changeBackground = (e, background) => {
    e.preventDefault();
    setCurrentBackground(background);
  }

  return (
    <div css={HeroStyle} {...props}>
      <motion.div className="overlay-title" variants={container} initial="hidden" animate="visible">
        <motion.span key={1} className="server" variants={item}>Durandal</motion.span>
        <motion.span key={2} className="separator" variants={item}> / </motion.span>
        <motion.span key={3} className="title" variants={item}>FFXIV</motion.span>
      </motion.div>
      { currentBackground && <div key={currentBackground} className="background">
        <p className="background-title">{currentBackground.title}</p>
        <ul className="background-selector">
          {data.allWpScreenshot.edges.map(({node}, index) => (
            <BackgroundSelectorItem key={index} screenshot={node} current={(currentBackground === node)} clickHandler={changeBackground} />
          ))}
        </ul>
        <motion.div
          className="background-animate"
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{
            duration: 1
          }}
        >
          <Background
            className="background-image"
            src={`${process.env.GATSBY_WP_IMGIX_URL}/${currentBackground.screenshot.mainscreenshot.mediaDetails.file}`}
            width={currentBackground.screenshot.mainscreenshot.mediaDetails.width}
            height={currentBackground.screenshot.mainscreenshot.mediaDetails.height}
            imgixParams={{ auto: 'compress,enhance,format' }} />
        </motion.div>
      </div> }
    </div>
  );
}

export default Hero;

const HeroStyle = css`
  position: relative;
  width: 100vw;
  height: calc(100vh - 1.5rem);

  background: #2B2D2E;

  /* .inner {
    position: relative;
    z-index: ${LayerIndex.overlay};
    width: calc(100% - 5rem);
    height: calc(100% - 5rem);
    padding: 2.5rem;

    display: flex;
    flex-direction: column;
    justify-content: flex-end;

    @media ${breakpoints.handheld} {
      width: calc(100% - 3rem);
      height: calc(100% - 5rem);
      padding: 2.5rem 1.5rem;
    }
  } */

  .overlay-title {
    position: absolute;
    left: 1.75rem;
    bottom: 1rem;
    transform: rotate(-90deg);
    transform-origin: top left;
    z-index: ${LayerIndex.overlay};

    @media ${breakpoints.xs} {
      left: 1.25rem;
      bottom: 2rem;
    }

    color: rgba(255,255,255, .75);
    font-weight: bold;
    text-transform: uppercase;
    text-align: right;
    ${Typography.h4};

    font-feature-settings: "palt";
    letter-spacing: .1em;
    line-height: 1;
    text-shadow: 0 0 2px rgba(0,0,0,.1);

    .server {
      display: inline-block;
      margin-right: .25em;
    }

    .separator {
      display: inline-block;
      margin-left: .25em;
      margin-right: .25em;

      font-weight: normal;
    }

    .title {
      margin-left: .25em;
      display: inline-block;
    }
  }

  .background {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: ${LayerIndex.base};

    .background-title {
      position: absolute;
      overflow: hidden;
      right: 0;
      bottom: -1rem;
      padding: .5rem 1.5rem;
      margin-block-start: 0;
      margin-block-end: 0;
      width: 10rem;
      z-index: ${LayerIndex.overlay};

      @media ${breakpoints.xs} {
        width: 8rem;
      }

      line-height: 1rem;
      letter-spacing: .1em;
      text-indent: .1em;
      font-size: .75rem;
      font-weight: bold;
      text-align: center;
      white-space: nowrap;
      text-overflow: ellipsis;

      background: #FAFDFE;
      border-radius: 1.5rem 0 0 1.5rem;
      box-shadow: 0 1px 0 rgba(0,0,0,.05);
    }

    .background-selector {
      position: absolute;
      right: 14rem;
      bottom: -1rem;

      margin-block-start: 0;
      margin-block-end: 0;
      padding-inline-start: 0;

      z-index: ${LayerIndex.overlay};

      list-style: none;

      @media ${breakpoints.xs} {
        right: auto;
        left: 1rem;
      }

      .selector-item {
        position: relative;
        display: inline-block;
        margin: 0 .25rem;
        vertical-align: top;
      }

      .item-pointer {
        position: relative;
        transition: transform .1s ease-in-out;
        overflow: hidden;
        display: block;
        padding: 0;
        border: none;
        outline: none;
        border-radius: 1rem;
        cursor: pointer;


        &[data-is-current="true"] {
          box-shadow: 0 0 0 2px rgba(255,255,255,1);
        }

        &:hover {
          transform: scale(1.5);
          box-shadow: 0 0 0 2px rgba(255,255,255,1);
          z-index: ${LayerIndex.overlay};
        }

        &:active {
          transform: scale(1.25);
          box-shadow: 0 0 0 2px rgba(255,255,255,1);
          z-index: ${LayerIndex.overlay};
        }
      }

      .selector-image {
        display: block;

        background: #FAFDFE;
      }
    }

    .background-animate {
      display: block;
      width: 100%;
      height: 100%;
    }

    .background-image {
      display: block;
      width: 100%;
      height: 100%;
    }
  }
`;
